// initial state
const initState = () => ({
  data: {},
})

export const initial = initState

const getters = {}

const mutations = {
  saveData(state, { data }) {
    state.data = data
  },
  reset(state) {
    Object.assign(state, initState())
  },
  saveUserData(state, { userData }) {
    state.data = userData
  },
  cleanUserData(state) {
    Object.assign(state.data, {})
  },
}

const actions = {}

export default {
  namespaced: true,
  state: initial,
  getters,
  actions,
  mutations,
}
