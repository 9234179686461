<template>
  <v-overlay
    :value="visible"
    opacity="0.2"
    z-index="100"
  >
    <v-progress-circular
      indeterminate
      size="64"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: 'Loaders',
  props: {
    visible: {
      type: Boolean,
      require: true,
    },
  },
}
</script>

<style lang="scss">
.loader {
  background: rgba(255, 255, 255, 0.8);
  transition: 0.15s ease-in opacity;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  opacity: 0;
  &--visible {
    opacity: 1;
    pointer-events: all;
  }
}
</style>
