<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    fixed
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/images/logos/logo.svg')"
          max-height="150px"
          max-width="200px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <nav-menu-link
        v-if="false"
        title="Dashboard"
        :to="{ name: 'dashboard' }"
        :icon="icons.mdiHomeOutline"
      ></nav-menu-link>
      <!-- Profile -->
      <nav-menu-link
        v-if="haveAccessToProfiles"
        title="Perfiles"
        :to="{ name: 'profile'}"
        :icon="icons.mdiAccount"
      ></nav-menu-link>
      <!-- Usuarios miembros -->
      <nav-menu-link
        v-if="haveAccessToManager"
        title="Funcionarios"
        :to="{ name: 'usuarios_miembros'}"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link>
      <!-- Areas -->
      <nav-menu-link
        v-if="haveAccessToAreas"
        title="Áreas"
        :to="{ name: 'areas'}"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link>
      <!-- Usuarios -->
      <nav-menu-link
        v-if="haveAccessToMembers"
        title="Ciudadanos"
        :to="{ name: 'usuarios'}"
        :icon="icons.mdiAccount"
      ></nav-menu-link>
      <!-- Registro de Incidente -->
      <nav-menu-link
        v-if="haveAccessToObras"
        title="Obras"
        :to="{ name: 'obras' }"
        :icon="icons.mdiHomeCity"
      ></nav-menu-link>

      <!-- Eventos-->
      <nav-menu-group
        v-if="haveAccessToEvents"
        title="Eventos"
        :icon="icons.mdiCalendarClock"
      >
        <nav-menu-link
          title="Gestionar eventos"
          :to="{ name: 'eventos' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="false"
          title="Reservas"
          :to="{ name: 'pages-login' }"
        ></nav-menu-link>
        <!-- Gestión de Categorías -->
        <nav-menu-link
          v-if="haveAccessToEventsCategories"
          title=" Categorías"
          :to="{ name: 'gestion_categorias_eventos' }"
        ></nav-menu-link>
        <!-- Gestión de Subcategorías -->
        <nav-menu-link
          v-if="haveAccessToEventsCategories"
          title="Subcategorías"
          :to="{ name: 'gestion_subcategorias_eventos' }"
        ></nav-menu-link>
      </nav-menu-group>

      <!-- Programas-->
      <nav-menu-group
        v-if="haveAccessToPrograms"
        title="Programas"
        :icon="icons.mdiBookOpenBlankVariant"
      >
        <nav-menu-link
          title="Gestionar programas"
          :to="{ name: 'programas' }"
        ></nav-menu-link>
        <nav-menu-link
          v-if="false"
          title="Reservas"
          :to="{ name: 'pages-login' }"
        ></nav-menu-link>
        <!-- Gestión de Categorías -->
        <nav-menu-link
          v-if="haveAccessToProgramsCategories"
          title=" Categorías"
          :to="{ name: 'gestion_categorias_programas' }"
        ></nav-menu-link>
        <!-- Gestión de Subcategorías -->
        <nav-menu-link
          v-if="haveAccessToProgramsCategories"
          title="Subcategorías"
          :to="{ name: 'gestion_subcategorias_programas' }"
        ></nav-menu-link>
      </nav-menu-group>
      <!-- Registro de Incidente
      <nav-menu-link
        v-if="haveAccessToIncidents"
        title="Registro de Incidente"
        :to="{ name: 'registro_incidente' }"
        :icon="icons.mdiTable"
      ></nav-menu-link>
      -->
      <!-- Notificaciones -->
      <nav-menu-link
        v-if="haveAccessToNotifications"
        title="Notificaciones"
        :to="{ name: 'notificaciones' }"
        :icon="icons.mdiBellCheck"
      ></nav-menu-link>
      <!-- Alertas -->
      <nav-menu-link
        v-if="haveAccessToAlertas"
        title="Alertas"
        :to="{ name: 'alerta' }"
        :icon="icons.mdiBell"
      ></nav-menu-link>
      <!-- Logs del Sistema -->
      <nav-menu-link
        v-if="false"
        title="Log del Sistema"
        :to="{ name: 'log_sistema' }"
      ></nav-menu-link>
      <!--
      <nav-menu-section-title title="USER INTERFACE"></nav-menu-section-title>
      -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiAccount,
  mdiBell,
  mdiBellCheck,
  mdiHomeCity,
  mdiBookOpenBlankVariant,
  mdiCalendarClock,
} from '@mdi/js'

// import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import { computed, ref } from '@vue/composition-api'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    // NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    let haveAccessToProfiles = ref(false)
    let haveAccessToEvents = ref(false)
    let haveAccessToEventsCategories = ref(false)
    let haveAccessToPrograms = ref(false)
    let haveAccessToProgramsCategories = ref(false)
    let haveAccessToNotifications = ref(false)
    let haveAccessToIncidents = ref(false)
    let haveAccessToManager = ref(false)
    let haveAccessToMembers = ref(false)
    let haveAccessToAreas = ref(false)
    let haveAccessToObras = ref(false)
    let haveAccessToAlertas = ref(false)
    const permissions = localStorage.getItem('permissions')

    if (permissions) {
      haveAccessToProfiles = computed(() => (permissions.includes('Profiles:Profiles:FullAccess') || (permissions.includes('Profiles:Profiles:ReadOnlyAccess'))))
      haveAccessToManager = computed(() => (permissions.includes('Users:Manager:FullAccess') || (permissions.includes('Users:Manager:ReadOnlyAccess'))))
      haveAccessToMembers = computed(() => (permissions.includes('Users:Members:FullAccess') || (permissions.includes('Users:Members:ReadOnlyAccess'))))
      haveAccessToEvents = computed(() => (permissions.includes('Events:Events:FullAccess') || (permissions.includes('Events:Events:ReadOnlyAccess'))))
      haveAccessToEventsCategories = computed(() => (permissions.includes('Events:Categories:FullAccess') || (permissions.includes('Events:Categories:ReadOnlyAccess'))))
      haveAccessToProgramsCategories = computed(() => (permissions.includes('Programs:Categories:FullAccess') || (permissions.includes('Programs:Categories:ReadOnlyAccess'))))
      haveAccessToPrograms = computed(() => (permissions.includes('Programs:Programs:FullAccess') || (permissions.includes('Programs:Programs:ReadOnlyAccess'))))
      haveAccessToIncidents = computed(() => (permissions.includes('Incidents:Incidents:FullAccess') || (permissions.includes('Incidents:Incidents:ReadOnlyAccess'))))
      haveAccessToNotifications = computed(() => (permissions.includes('Notifications:Notifications:FullAccess') || (permissions.includes('Notifications:Notifications:ReadOnlyAccess'))))
      haveAccessToAreas = computed(() => (permissions.includes('Areas:Areas:FullAccess') || (permissions.includes('Areas:Areas:ReadOnlyAccess'))))
      haveAccessToObras = computed(() => (permissions.includes('Obras:Obras:FullAccess') || (permissions.includes('Obras:Obras:ReadOnlyAccess'))))
      haveAccessToAlertas = computed(() => (permissions.includes('Alertas:Alertas:FullAccess') || (permissions.includes('Alertas:Alertas:ReadOnlyAccess'))))
    }

    return {
      permissions,
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiAccount,
        mdiBell,
        mdiBellCheck,
        mdiHomeCity,
        mdiBookOpenBlankVariant,
        mdiCalendarClock,
      },

      // Computed
      haveAccessToProfiles,
      haveAccessToEvents,
      haveAccessToEventsCategories,
      haveAccessToPrograms,
      haveAccessToProgramsCategories,
      haveAccessToNotifications,
      haveAccessToIncidents,
      haveAccessToManager,
      haveAccessToMembers,
      haveAccessToAreas,
      haveAccessToObras,
      haveAccessToAlertas,

      menu: [
        { title: 'Dashboard', url: 'dashboard', icon: 'mdiHomeOutline' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
