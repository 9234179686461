import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  /* {
    path: '/',
    redirect: 'checkotp',
  }, */
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: false,
      authorize: [],
    },
  },

  // Usuarios Miembros
  {
    path: '/usuarios-miembros',
    name: 'usuarios_miembros',
    component: () => import('@/views/usuarios_miembros/Index.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Users:Manager:FullAccess', 'Users:Manager:ReadOnlyAccess'],
    },
  },
  {
    path: '/usuarios-miembros/crear-usuarios-miembros',
    name: 'crear_usuarios_miembros',
    component: () => import('@/views/usuarios_miembros/form/crear.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Users:Manager:FullAccess'],
    },
  },
  {
    path: '/usuarios-miembros/cambiar-estado-usuarios-miembros/:id',
    name: 'editar_usuarios_miembros',
    component: () => import('@/views/usuarios_miembros/form/editar1.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Users:Manager:FullAccess', 'Users:Manager:ReadEditAccess'],
    },
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: () => import('@/views/usuarios_comunes/Index.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Users:Members:FullAccess', 'Users:Members:ReadOnlyAccess'],
    },
  },

  // Eventos
  {
    path: '/eventos',
    name: 'eventos',
    component: () => import('@/views/eventos/Index.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Events:Events:FullAccess', 'Events:Events:ReadOnlyAccess'],
    },
  },
  {
    path: '/eventos/crear-eventos',
    name: 'crear_eventos',
    component: () => import('@/views/eventos/form/crear.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Events:Events:FullAccess', 'Events:Events:CreateAccess'],
    },
  },
  {
    path: '/eventos/editar-eventos/:id',
    name: 'editar_eventos',
    component: () => import('@/views/eventos/form/editar.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Events:Events:FullAccess', 'Events:Events:ReadEditAccess', 'Events:Events:ApproveAccess'],
    },
  },
  {
    path: '/eventos/duplicar-eventos/:id',
    name: 'duplicar_eventos',
    component: () => import('@/views/eventos/form/duplicar.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Events:Events:FullAccess', 'Events:Events:ReadEditAccess'],
    },
  },

  // Programas
  {
    path: '/programas',
    name: 'programas',
    component: () => import('@/views/programas/Index.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Programs:Programs:FullAccess', 'Programs:Programs:ReadOnlyAccess'],
    },
  },
  {
    path: '/programas/crear-programas',
    name: 'crear_programas',
    component: () => import('@/views/programas/form/crear.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Programs:Programs:FullAccess', 'Programs:Programs:CreateAccess'],
    },
  },
  {
    path: '/programas/editar-programas/:id',
    name: 'editar_programas',
    component: () => import('@/views/programas/form/editar.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Programs:Programs:FullAccess', 'Programs:Programs:ReadEditAccess', 'Programs:Programs:ApproveAccess'],
    },
  },
  {
    path: '/programas/duplicar-programas/:id',
    name: 'duplicar_programas',
    component: () => import('@/views/programas/form/duplicar.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Programs:Programs:FullAccess', 'Programs:Programs:ReadEditAccess'],
    },
  },

  // Obras
  {
    path: '/obras',
    name: 'obras',
    component: () => import('@/views/obras/Index.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Obras:Obras:FullAccess', 'Obras:Obras:ReadOnlyAccess'],
    },
  },
  {
    path: '/obras/crear-obras',
    name: 'crear_obras',
    component: () => import('@/views/obras/form/crear.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Obras:Obras:FullAccess', 'Obras:Obras:CreateAccess'],
    },
  },
  {
    path: '/obras/editar-obras/:id',
    name: 'editar_obras',
    component: () => import('@/views/obras/form/editar.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Obras:Obras:FullAccess', 'Obras:Obras:ReadEditAccess', 'Obras:Obras:ApproveAccess'],
    },
  },
  {
    path: '/obras/detallelogs/:id',
    name: 'detalle_logs',
    component: () => import('@/views/obras/form/detallelogs.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Obras:Obras:FullAccess'],
    },
  },
  {
    path: '/obras/duplicar-obras/:id',
    name: 'duplicar_obras',
    component: () => import('@/views/obras/form/duplicar.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Obras:Obras:FullAccess', 'Obras:Obras:ReadEditAccess'],
    },
  },

  // Alertas
  {
    path: '/alerta',
    name: 'alerta',
    component: () => import('@/views/alerta/index.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: false,
      authorize: [],
    },
  },
  {
    path: '/alerta/crear-alerta',
    name: 'crear_alerta',
    component: () => import('@/views/alerta/form/crear.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: false,
      authorize: [],
    },
  },
  {
    path: '/alerta/duplicar-alerta/:id',
    name: 'duplicar_alerta',
    component: () => import('@/views/alerta/form/duplicar.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: false,
      authorize: [],
    },
  },
  {
    path: '/alerta/editar-alerta/:id',
    name: 'editar_alerta',
    component: () => import('@/views/alerta/form/editar.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: false,
      authorize: [],
    },
  },

  // Log del Sistema
  {
    path: '/log-sistema',
    name: 'log_sistema',
    component: () => import('@/views/systemlog/index.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: false,
      authorize: [],
    },
  },
  {
    path: '/systemlogs/details/:id',
    name: 'details_log_sistema',
    component: () => import('@/views/systemlog/detallelogs.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: false,
      authorize: [],
    },
  },

  // Notificaciones
  {
    path: '/notificaciones',
    name: 'notificaciones',
    component: () => import('@/views/notificaciones/index.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Notifications:Notifications:FullAccess', 'Notifications:Notifications:ReadOnlyAccess'],
    },
  },
  {
    path: '/notificaciones/crear-notificaciones',
    name: 'crear_notificaciones',
    component: () => import('@/views/notificaciones/form/crear.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Notifications:Notifications:FullAccess'],
    },
  },
  {
    path: '/notificaciones/duplicar-notificaciones/:id',
    name: 'duplicar_notificaciones',
    component: () => import('@/views/notificaciones/form/duplicar.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Notifications:Notifications:FullAccess', 'Notifications:Notifications:ReadEditAccess'],
    },
  },
  {
    path: '/notificaciones/editar-notificaciones/:id',
    name: 'editar_notificaciones',
    component: () => import('@/views/notificaciones/form/editar.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Notifications:Notifications:FullAccess', 'Notifications:Notifications:ReadEditAccess'],
    },
  },

  // Profile
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/index.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Profiles:Profiles:FullAccess', 'Profiles:Profiles:ReadOnlyAccess'],
    },
  },
  {
    path: '/profile/form',
    name: 'gestion_profile_form',
    component: () => import('@/views/profile/form.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Profiles:Profiles:FullAccess', 'Profiles:Profiles:ReadEditAccess'],
    },
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: false,
      authorize: [],
    },
  },
  {
    path: '/gestion-categorias-eventos',
    name: 'gestion_categorias_eventos',
    component: () => import('@/views/gestion_categorias_eventos/index.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Events:Categories:FullAccess', 'Events:Categories:ReadOnlyAccess'],
    },
  },
  {
    path: '/gestion-categorias-eventos/form',
    name: 'gestion_categorias_eventos_form',
    component: () => import('@/views/gestion_categorias_eventos/form.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Events:Categories:FullAccess', 'Events:Categories:ReadEditAccess'],
    },
  },
  {
    path: '/gestion-subcategorias-eventos',
    name: 'gestion_subcategorias_eventos',
    component: () => import('@/views/gestion_subcategorias_eventos/index.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Events:Categories:FullAccess', 'Events:Categories:ReadOnlyAccess'],
    },
  },
  {
    path: '/gestion-subcategorias-eventos/form',
    name: 'gestion_subcategorias_eventos_form',
    component: () => import('@/views/gestion_subcategorias_eventos/form.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Events:Categories:FullAccess', 'Events:Categories:ReadEditAccess'],
    },
  },
  {
    path: '/gestion-categorias-programas',
    name: 'gestion_categorias_programas',
    component: () => import('@/views/gestion_categorias_programas/index.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Programs:Categories:FullAccess', 'Programs:Categories:ReadOnlyAccess'],
    },
  },
  {
    path: '/gestion-categorias-programas/form',
    name: 'gestion_categorias_programas_form',
    component: () => import('@/views/gestion_categorias_programas/form.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Programs:Categories:FullAccess', 'Programs:Categories:ReadEditAccess'],
    },
  },
  {
    path: '/gestion-subcategorias-programas',
    name: 'gestion_subcategorias_programas',
    component: () => import('@/views/gestion_subcategorias_programas/index.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Programs:Categories:FullAccess', 'Programs:Categories:ReadOnlyAccess'],
    },
  },
  {
    path: '/gestion-subcategorias-programas/form',
    name: 'gestion_subcategorias_programas_form',
    component: () => import('@/views/gestion_subcategorias_programas/form.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Programs:Categories:FullAccess', 'Programs:Categories:ReadEditAccess'],
    },
  },
  {
    path: '/registro-incidente',
    name: 'registro_incidente',
    component: () => import('@/views/registro_incidente/index.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Incidents:Incidents:FullAccess', 'Incidents:Incidents:ReadOnlyAccess'],
    },
  },
  {
    path: '/registro-incidente/detail',
    name: 'registro_incidente_detail',
    component: () => import('@/views/registro_incidente/detail.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Incidents:Incidents:FullAccess', 'Incidents:Incidents:ReadOnlyAccess'],
    },
  },
  {
    path: '/registro-incidente/form',
    name: 'registro_incidente_form',
    component: () => import('@/views/registro_incidente/detail.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Incidents:Incidents:FullAccess', 'Incidents:Incidents:ReadEditAccess'],
    },
  },
  {
    path: '/otp',
    name: 'otp',
    component: () => import('@/views/otp/Otp.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
      requirePermissions: false,
      authorize: [],
    },
  },
  {
    path: '/areas',
    name: 'areas',
    component: () => import('@/views/areas/index.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Areas:Areas:FullAccess', 'Areas:Areas:ReadEditAccess'],
    },
  },
  {
    path: '/areas/form',
    name: 'areas_form',
    component: () => import('@/views/areas/form.vue'),
    meta: {
      requiresAuth: true,
      requirePermissions: true,
      authorize: ['Areas:Areas:FullAccess', 'Areas:Areas:ReadEditAccess'],
    },
  },
  {
    path: '/login_callback',
    name: 'login_callback',
    component: () => import('@/views/login_callback/index.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
      requirePermissions: false,
      authorize: [],
    },
  },
  {
    path: '/validacion-correo',
    name: 'validacion-correo',
    component: () => import('@/views/validacion_correo.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
      requirePermissions: false,
      authorize: [],
    },
  },
  {
    path: '/conexion-perdida',
    name: 'conexion-perdida',
    component: () => import('@/views/conexion-perdida.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
      requirePermissions: false,
      authorize: [],
    },
  },
  {
    path: '/loggedout',
    name: 'loggedout',
    component: () => import('@/views/loggedout.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
      requirePermissions: false,
      authorize: [],
    },
  },
  {
    path: '/error-401',
    name: 'error-401',
    component: () => import('@/views/401.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
      requirePermissions: false,
      authorize: [],
    },
  },
  {
    path: '/mfa-fail',
    name: 'mfa-fail',
    component: () => import('@/views/otp_fail.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
      requirePermissions: false,
      authorize: [],
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: false,
      requirePermissions: false,
      authorize: [],
    },
  },
  {
    path: '*',
    redirect: 'error-404',
    meta: {
      layout: 'blank',
      requiresAuth: false,
      requirePermissions: false,
      authorize: [],
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// navigation guards before each
router.beforeEach(async (to, from, next) => {
  if (to.matched.some(value => value.meta && value.meta.requiresAuth === true)) {
    const token = localStorage.getItem('token')
    if (!token) {
      localStorage.clear()
      window.location.replace(process.env.VUE_APP_API_AZURE_AD);

    } else {
      const permissions = localStorage.getItem('permissions')
      const { authorize, requirePermissions } = to.meta

      // Este caso es para la raiz, si el usuario esta autenticado, almenos puede
      // acceder a la raiz del sistema
      if (!requirePermissions && authorize.length === 0) {
        next()
      }

      // Si el usuario tiene entre sus permisos, el o los permisos que se requieren para la ruta
      // Pasa en caso contrario no lo deja pasar
      if (authorize.length > 0) {
        let count = 0
        for (let index = 0; index < authorize.length; index += 1) {
          const element = authorize[index]
          if (permissions.includes(element)) {
            count += 1
          }
        }
        if (count >= 1) {
          next()
        } else {
          next({ name: 'error-404' })
        }
      }
    }
  } else {
    next()
  }
})

export default router
