<template>
  <div
    id="app"
  >
    <!-- El componente Loading sera usado en toda la aplicacion -->
    <loader :visible="loading" />
    <component :is="resolveLayout">
      <router-view></router-view>
      <!-- global snackbar -->
      <v-snackbar
        v-model="snackbar.show"
        :color="snackbar.color"
        :timeout="3000"
        app
        centered
        multi-line
        top
      >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            @click="snackbar.show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    <!-- <upgrade-to-pro></upgrade-to-pro> -->
    </component>
  </div>
</template>

<script>
import { computed, reactive, ref } from '@vue/composition-api'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import EventBus from '@/utils/eventbus'

// Importar el Componente Loading para luego ser usado en toda la aplicacion
import Loader from '@/components/Loader.vue'

Vue.use(VueRouter)

// import UpgradeToPro from './components/UpgradeToPro.vue'

export default {
  components: {
    Loader, // Declarando el componente Loading
    LayoutBlank,
    LayoutContent,

    // UpgradeToPro,
  },

  // _, context
  setup(_, context) {
    // console.log('Ejecutando Cliente')

    const { $store } = context.root

    const loading = computed(() => ($store.state.loader.loading)) // capturar el estado de loading para ver si hay alguna peticion pendiente
    // const loading = ref(false)
    const { route, router } = useRouter()
    const snackbar = reactive({
      show: false,
      text: '',
      color: '',
    })
    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })
    EventBus.$on('SHOW_SNACKBAR', e => {
      snackbar.value = {
        show: true,
        text: e.text,
        color: e.color,
      }
    })
    EventBus.$on('AUTH_FAILED', () => {
      snackbar.value = {
        show: true,
        text: 'Sesión expirada',
        color: 'error',
      }
      localStorage.clear()
      window.location.replace(process.env.VUE_APP_API_AZURE_AD)
    })
    EventBus.$on('SERVER_ERROR', () => {
      snackbar.value = {
        show: true,
        text: 'Server Error',
        color: 'error',
      }
    })
    EventBus.$on('ACCESS_DENIED', () => {
      snackbar.value = {
        show: true,
        text: 'Operación no permitida',
        color: 'error',
      }
      router.replace({ name: 'error-404' })
    })

    return {
      loading,
      resolveLayout,
      snackbar,
    }
  },
}
</script>
