<template>
  <v-app>
    <vertical-nav-menu
      :key="keyComponent"
      :is-drawer-open.sync="isDrawerOpen"
    ></vertical-nav-menu>

    <v-app-bar
      app
      flat
      absolute
      color="transparent"
    >
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon
            class="d-block d-lg-none me-2"
            @click="isDrawerOpen = !isDrawerOpen"
          ></v-app-bar-nav-icon>
          <!-- Buscar -->
          <v-text-field
            v-if="false"
            rounded
            dense
            outlined
            :prepend-inner-icon="icons.mdiMagnify"
            class="app-bar-search flex-grow-0"
            hide-details
          ></v-text-field>

          <v-spacer></v-spacer>

          <!-- Right Content -->
          <theme-switcher></theme-switcher>
          <v-btn
            v-if="false"
            icon
            small
            class="ms-3"
          >
            <v-icon>
              {{ icons.mdiBellOutline }}
            </v-icon>
          </v-btn>
          <app-bar-user-menu
            :key="keyComponentProfile"
            :nombre="nombre"
            :usuario="usuario"
          ></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <slot></slot>
      </div>
    </v-main>

    <v-footer
      app
      inset
      color="transparent"
      absolute
      height="56"
      class="px-0"
    >
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <span>
            &copy; {{ anio }} <a
              :href="urlBase"
              class="text-decoration-none"
              target="_blank"
            >GYE</a></span>
          <span
            v-if="false"
            class="d-sm-inline d-none"
          >
            <a
              href="https://themeselection.com/products/category/download-free-admin-templates/"
              target="_blank"
              class="me-6 text--secondary text-decoration-none"
            >Freebies</a>
            <a
              href="https://themeselection.com/blog/"
              target="_blank"
              class="me-6 text--secondary text-decoration-none"
            >Blog</a>
            <a
              href="https://github.com/themeselection/materio-vuetify-vuejs-admin-template-free/blob/main/LICENSE"
              target="_blank"
              class="text--secondary text-decoration-none"
            >MIT Licence</a>
          </span>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { mdiMagnify, mdiBellOutline, mdiGithub } from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import HTTP from '@/utils/axios/axios-config-base'

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
  },
  setup() {
    const anio = new Date().getFullYear()
    const urlBase = process.env.VUE_APP_API_BASE_URL
    const isDrawerOpen = ref(null)
    const nombre = ref()
    const usuario = ref()
    const keyComponent = ref(0)
    const keyComponentProfile = ref(0)
    const getCurrentPermissionUser = async () => {

      const URL = `${HTTP.defaults.baseURL}identity-api/user/me`
      const resp = await HTTP.get(URL)
      if (resp.status === 200) {
        nombre.value = resp.data.firstName
        usuario.value = resp.data.username
        localStorage.setItem('permissions', [])
        localStorage.setItem('permissions', resp.data.permissions)
        keyComponentProfile.value += 1
        keyComponent.value += 1
      }
    }
    getCurrentPermissionUser()

    onMounted(() => {
      keyComponent.value += 1
      keyComponentProfile.value += 1
     
    })

    return {
      isDrawerOpen,

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
      },
      urlBase,
      anio,
      nombre,
      usuario,
      keyComponent,
      keyComponentProfile,
      getCurrentPermissionUser,
    }
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
</style>
