import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import VueRouter from 'vue-router'
import mapboxgl from 'mapbox-gl'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import HTTP from '@/utils/axios/axios-config-base'

mapboxgl.accessToken = 'pk.eyJ1Ijoiam9zZTg5MDgyMyIsImEiOiJja3plZjIzczMwZjEwMm9vYmdpam81b2Y4In0.4BCud0O62PlRyUwXYVaXCQ'

Vue.config.productionTip = false
Vue.use(VueRouter)

new Vue({
  router,
  store,
  vuetify,
  created() {
    HTTP.interceptors.request.use(
      config => {
        if (config.showLoader) {
          store.dispatch('loader/pending')
        }

        return config
      },
      error => {
        if (error.config.showLoader) {
          store.dispatch('loader/done')
        }

        return Promise.reject(error)
      },
    )
    HTTP.interceptors.response.use(
      response => {
        if (response.config.showLoader) {
          store.dispatch('loader/done')
        }

        return response
      },
      error => {
        const { response } = error

        if (response.config.showLoader) {
          store.dispatch('loader/done')
        }

        return Promise.reject(error)
      },
    )
  },
  render: h => h(App),
}).$mount('#app')
