require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#0099F7',
        accent: '#F6F6F6',
        secondary: '#112CA3',
        success: '#5DB075',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#9C0000',
      },
      dark: {
        primary: '#0099F7',
        accent: '#F6F6F6',
        secondary: '#112CA3',
        success: '#5DB075',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#9C0000',
      },
    },
  },
}
