import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/auth'
import loader from '@/store/modules/loader'

Vue.use(Vuex)

// initial state
const initState = () => ({
  loader: false,
})

export const state = initState

export default new Vuex.Store({
  state,
  mutations: {},
  actions: {},
  modules: {
    auth,
    loader,
  },
})
